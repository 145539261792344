<template>
  <div class="p40">
    <div class="flex" style="justify-content: space-between">
      <h6 style="letter-spacing: 0">Massmailer</h6>
      <button v-if="state == 'mails'" @click="state = ''" class="brown500">
        New Massmail
      </button>
      <button v-else class="brown500" @click="state = 'mails'">
        Back to massmail list
      </button>
    </div>
    <div class="mt40" v-if="state == 'mails'">
      <table v-if="massMails.length > 0" class="ui celled table">
        <thead>
          <tr>
            <th>Created On</th>
            <th>Groups</th>
            <th>Subject</th>
            <th>Content</th>
            <th>Sent To</th>
            <th>Pending</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="m of massMails" :key="m.id">
            <td>{{ m.created_at }}</td>
            <td>
              <div v-for="(q, i) in m.query.slice(0, 10)" :key="i">
                {{ getGroupName(q) }}
                {{
                  m.query && m.query.length > 10
                    ? ` + ${m.query.length} more`
                    : ""
                }}
              </div>
            </td>
            <td>{{ m.Subject }}</td>
            <td>
              <div v-html="addUnsubscribeLink(m.Content)"></div>
            </td>
            <td>
              {{ m.sent_to ? m.sent_to.length : 0 }} -
              {{
                Math.round(
                  ((m.sent_to ? m.sent_to.length : 0) * 100) / m.total
                )
              }}%
            </td>
            <td>
              <button @click="logCount(m)">Check Count</button>
              <button @click="fixCount(m)">Fix Count</button>
            </td>
            <td>{{ m.total }}</td>
            <td class="flex">
              <button
                @click="updateCompleted(m.id, m.Completed)"
                :class="[m.Completed ? 'green' : 'red']"
                class="px12"
              >
                <i v-if="m.Completed" class="play icon"></i>
                <i v-else class="stop icon"></i>
              </button>
              <!-- button @click="editMassmail" class="brown500 button">Edit</button -->
              <button @click="deleteMassmail(m.id)" class="px12 ml8 red button">
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else>
        <h6 class="fs14" style="font-style: italic">No Massmails</h6>
      </div>
    </div>
    <div v-if="state == ''">
      <div class="flex wrap">
        <span class="mr12 mt12 vertical flex">
          <label>Type</label>
          <select class="mt4" v-model="form.type" placeholder="User Type">
            <option selected value="">All</option>
            <option v-for="t in userTypes" :key="t">{{ t }}</option>
          </select>
        </span>
        <span class="mr12 mt12 vertical flex">
          <label>Field of Study</label>
          <select class="mt4" v-model="form.field">
            <option selected value="">All</option>
            <option v-for="f in filteredFields" :key="f" :value="f">
              {{ f }}
            </option>
          </select>
        </span>
        <span class="mr12 mt12 vertical flex">
          <label class="mt4">Education Level</label>
          <select v-model="form.levelOfEducation">
            <option selected value="">All</option>
            <option v-for="l in educationLevels" :key="l" :value="l">
              {{ l }}
            </option>
          </select>
        </span>
        <span class="mr12 mt12 vertical flex">
          <label class="mt4">YoG eq</label>
          <select class="wfill" v-model="yogeq">
            <option selected value="">=</option>
            <option value="_gte">&#62;=</option>
            <option value="_lte">&#60;=</option>
          </select>
        </span>

        <span class="mr12 mt12 vertical flex">
          <label>Year of Graduation</label>
          <select class="mt4 wfill" v-model="form.yearOfGraduation">
            <option selected value="">All</option>
            <option v-for="y in years" :key="y" :value="y">{{ y }}</option>
          </select>
        </span>
        <span class="mr12 mt12 vertical flex">
          <label class="mt4">Country</label>
          <select class="wfill" v-model="form.country">
            <option selected value="">India</option>
            <option value="neq">International</option>
          </select>
        </span>
        <span class="mr12 mt12 vertical flex">
          <label>Zone</label>
          <select class="mt4 wfill" v-model="form.zone">
            <option selected value="">All</option>
            <option v-for="z in zones" :key="z" :value="z">{{ z }}</option>
          </select>
        </span>
        <span class="mr12 mt12 vertical flex">
          <label>State</label>
          <select
            :key="filteredStates.length"
            class="mt4 wfill"
            v-model="form.state"
          >
            <option selected value="">All</option>
            <option v-for="z in filteredStates" :key="z" :value="z">
              {{ z }}
            </option>
          </select>
        </span>
        <span class="mr12 mt12 vertical flex">
          <label>City</label>
          <search-dropdown
            ref="cityDropdown"
            @selected="form.city = arguments[0]"
          >
            <div
              class="item"
              v-for="u in filteredCities"
              :key="u"
              :data-value="u"
            >
              {{ u }}
            </div>
          </search-dropdown>
          <!-- select class="mt4 wfill" v-model="form.city">
            <option selected value="">All</option>
            <option v-for="z in filteredCities" :key="z" :value="z">{{z}}</option>
          </select -->
        </span>
        <span class="mr12 mt12 vertical flex">
          <label>University</label>
          <search-dropdown
            ref="instituteDropdown"
            @selected="form.institute = arguments[0]"
          >
            <div
              class="item"
              v-for="u in filteredUnis"
              :key="u.id"
              :data-value="u.name + ', ' + u.city"
            >
              {{ u.name + ", " + u.city }}
            </div>
          </search-dropdown>
          <!-- select class="mt4 wfill" v-model="form.institute">
            <option selected value="">All</option>
            <option v-for="u in filteredUnis" :key="u.id" :value="u.name + ', ' + u.city">{{u.name}}, {{u.city}}</option>
          </select -->
        </span>
        <span class="mr12 mt12 vertical flex">
          <label>Connectaid Job Seeker</label>
          <input type="checkbox" v-model="form.connectaidJobSeeker" />
        </span>
        <span class="mr12 mt12 vertical flex">
          <label>Acegate Learner</label>
          <input type="checkbox" v-model="form.acegateLearner" />
        </span>
      </div>
      <button @click="addMailingList" class="mt12 brown500">Add List</button>
      <button @click="addMiscUsers" class="mx12 mt12 brown500">
        Add Null Users
      </button>
      <button @click="upload" class="ml12 mt12 black">Upload List</button>

      <h5 class="mt28">Custom Mailing List</h5>
      <div class="flex">
        <div class="mt12">
          <label>Select Mailing Lists</label> <br />
          <select class="mt12" v-model="selectedMailingList">
            <option disabled selected value="">Select List</option>
            <option
              v-for="list in mailingLists"
              :key="list.id"
              :value="list.id"
            >
              {{ list.name }}
            </option>
          </select>
          <button @click="addCustomMailingList" class="ml8 mt12 black">
            Add Custom List
          </button>
        </div>
        <div class="mt12 ml20">
          <label>Upload Mailing Lists</label> <br />
          <input
            class="ml0 mt12"
            style="padding: 10px 8px"
            placeholder="List Name"
            v-model="listName"
          />
          <button @click="uploadCreate" class="ml12 mt12 green">
            Upload A List to Database
          </button>
        </div>
      </div>

      <div v-for="(group, i) in mailList" :key="i" class="mail-group">
        {{ getGroupName(group) }} - {{ counts[i] }} emails
        <i
          @click="showList(i)"
          style="margin: 0 4px"
          class="pointer eye icon"
        ></i>
        <i
          @click="deleteList(i)"
          style="margin: 0 4px"
          class="pointer close icon"
        ></i>
      </div>
      <div style="width: 200px" class="mt20 vertical flex">
        <label>Emails per hour</label>
        <input
          type="number"
          value="100"
          v-model="EmailsPerBlast"
          placeholder="Emails per hour"
        />
      </div>
      <div style="width: 200px" class="mt20 vertical flex">
        <label>Source</label>
        <select class="mt4" v-model="source">
          <option value="info@ethosempowers.com">info@ethosempowers.com</option>
          <option value="gita@ethosempowers.com">gita@ethosempowers.com</option>
        </select>
      </div>
      <input
        style="margin: 20px 0px 0px 0px"
        v-model="subject"
        class="email-content"
        placeholder="Subject"
        type="text"
      />
      <div>
        <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
          <div class="menubar">
            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.bold() }"
              @click="commands.bold"
            >
              <i class="bold icon" />
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.italic() }"
              @click="commands.italic"
            >
              <i class="italic icon" />
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.underline() }"
              @click="commands.underline"
            >
              <i class="underline icon" />
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.paragraph() }"
              @click="commands.paragraph"
            >
              <i class="paragraph icon" />
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.heading({ level: 1 }) }"
              @click="commands.heading({ level: 1 })"
            >
              H1
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.heading({ level: 2 }) }"
              @click="commands.heading({ level: 2 })"
            >
              H2
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.heading({ level: 3 }) }"
              @click="commands.heading({ level: 3 })"
            >
              H3
            </button>
            <button
              class="menubar__button"
              :class="{ 'is-active': isActive.link() }"
              @click="commands.link()"
            >
              <i class="chain icon" />
            </button>

            <button class="menubar__button" @click="commands.undo">
              <i class="undo icon" />
            </button>

            <button class="menubar__button" @click="commands.redo">
              <i class="redo icon" />
            </button>
          </div>
        </editor-menu-bar>
        <editor-content
          style="margin: 20px 0px 0px 0px"
          class="editor email-content content"
          :editor="editor"
        />
      </div>
      <div class="ui modal py20 px40" id="mailingModal">
        <h6>Mailing List</h6>
        <!-- input class="mt12" placeholder="Search Users" v-model="listSearch" -->
        <table :key="modalKey" class="ui celled table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Type</th>
              <th>Contact</th>
              <th>Accepts Massmail</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in listUsers" :key="user.id">
              <td>{{ user.name }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.type }}</td>
              <td>{{ user.contact }}</td>

              <td>{{ user.acceptingMassmail }}</td>
            </tr>
          </tbody>
        </table>
        <pagination
          :pages="userPages"
          :key="paginationKey"
          @gotoPage="page = arguments[0]"
        />
        <!-- div v-if="userPages" class="wfill flex">
          <div style="margin: 0 auto;" class="mlauto ui pagination menu">
            <a @click="page = 0" v-if="page != 0" class="item">
              1
            </a>
            <div @click="page = page - 1" v-if="page != 0" class="item">
              <i class="angle left icon" ></i>
            </div>
            <a @click="page = n - 1"
               v-for="(n) in new Array(userPages).fill(0).map((num, i) => i + 1).slice(page, page + 3)" :key="n" class="item">
              {{n}}
            </a>
            <div @click="page = page + 1" v-if="userPages > 4 && page < userPages - 2" class="item">
              <div 
                style="margin: 0px; padding: 0; min-width: 0px;" class="item">
                <i class="angle right icon" ></i>
              </div>
            </div>
            <a @click="page = userPages - 1" v-if="userPages > 4" class="item">
              {{userPages}}
            </a>
          </div
        </div -->
      </div>
      <div class="mt20 flex">
        <button class="black" @click="sendTestEmail">Send Test Email</button>
        <div v-for="(v, k) in testEmails" :key="k" class="ml12 flex">
          <input class="myauto" v-model="testEmails[k]" type="checkbox" />
          <p style="margin: auto 8px" class="ml8">{{ k }}</p>
        </div>
        <button @click="createMassmailJob" class="mlauto brown500">
          Create Massmail
        </button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import pagination from "../components/pagination";
import searchDropdown from "../components/search-dropdown";
import moment from "moment";
import qs from "qs";
import universities from "../universities";
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
} from "tiptap-extensions";
export default {
  name: "mass-mailer",
  mixins: [universities],
  components: {
    EditorMenuBar,
    EditorContent,
    searchDropdown,
    pagination,
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  data() {
    return {
      massMails: [],
      mailingLists: [],
      selectedMailingList: "",
      search: "",
      source: "info@ethosempowers.com",
      activeFilter: null,
      activeIndex: null,
      modalKey: this.$bus.random(),
      paginationKey: this.$bus.random(),
      listSearch: "",
      page: 0,
      listUsers: [],
      dbUnis: [],
      cities: [],
      states: [],
      yogeq: "",
      form: {
        levelOfEducation: "",
        field: "",
        yearOfGraduation: "",
        type: "",
        institute: "",
        zone: "",
        state: "",
        city: "",
        country: "",
        connectaidJobSeeker: "",
        acegateLearner: "",
        acceptingMassmail: true,
      },
      listName: "",
      testEmails: {
        "info@ethosempowers.com": true,
        // "arcause.ethos@gmail.com": true,
        // "helloacedge@gmail.com": true,
        "ethosindia.helpdesk@gmail.com": true,
        // "gita@ethosempowers.com": true,
      },
      zones: ["North", "South", "East", "West", "International"],
      EmailsPerBlast: 100,
      state: "mails",
      list: [],
      mailList: [],
      counts: [],
      educationLevels: [
        "Middle School",
        "High School",
        "Diploma",
        "Undergraduate",
        "Postgraduate",
        "Doctorate",
      ],
      userTypes: ["Student", "Professional", "Faculty"],
      subject: "",
      content: "",
      editor: new Editor({
        content: "",
        onUpdate: ({ getHTML }) => {
          this.content = getHTML();
        },
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
        ],
      }),
    };
  },
  watch: {
    activeFilter: function (v) {
      // this.page = 0
      let form = { ...v };
      let f = this.$bus.processForm(form, this.dbUnis, this.yogeq);
      let users = this.$req
        .post(
          `getUsers`,
          {
            filter: f,
            page: 0,
            limit: 10,
          },
          this.$bus.headers
        )
        .then((r) => {
          console.log(r.data);
          this.listUsers = r.data.users;
          this.modalKey = this.$bus.random();
          this.paginationKey = this.$bus.random();
        })
        .catch((e) => console.log(e));
    },
    page: function (v) {
      let form = { ...this.activeFilter };
      let f = this.$bus.processForm(form, this.dbUnis, this.yogeq);
      let users = this.$req
        .post(
          `getUsers`,
          {
            filter: f,
            page: v,
            limit: 10,
          },
          this.$bus.headers
        )
        .then((r) => {
          this.listUsers = r.data.users;
          this.modalKey = this.$bus.random();
        })
        .catch((e) => console.log(e));
    },
    "form.zone": function () {
      this.form.university = "";
      this.form.city = "";
      this.form.state = "";
      this.form.institute = "";
    },
    "form.state": function () {
      this.form.city = "";
      this.form.institute = "";
    },
    "form.city": function () {
      this.form.institute = "";
      /* eslint-disable-next-line */
      $(this.$refs.instituteDropdown).dropdown("set value", "");
    },
  },
  mounted() {
    // Get the last 10 massmails from strapi
    this.$req
      .get(`mass-mails?_limit=10&_sort=created_at:DESC`, {
        headers: {
          Authorization: `Bearer ${this.$bus.token}`,
        },
      })
      .then((r) => {
        this.massMails = r.data.sort(
          (m1, m2) =>
            moment(m2.created_at).unix() - moment(m1.created_at).unix()
        );
      })
      .catch((e) => console.log(e));
    this.$req
      .get("universities?_limit=-1")
      .then((r) => {
        this.dbUnis = r.data;
        this.cities = r.data
          .map((u) => u.city)
          .filter((v, i, s) => s.indexOf(v) == i)
          .sort();
        this.states = r.data
          .map((u) => u.state)
          .filter((v, i, s) => s.indexOf(v) == i)
          .sort();
      })
      .catch((e) => console.log(e));

    this.$req
      .get("mailing-lists?_limit=10", this.$bus.headers)
      .then((r) => {
        this.mailingLists = r.data;
      })
      .catch((e) => console.log(e));
  },
  computed: {
    years: function () {
      return new Array(70).fill(1).map((n, i) => {
        return 1960 + i;
      });
    },
    filteredFields: function () {
      return [
        "Architecture",
        "Interior Design",
        "Civil Engineering",
        "Planning",
        "Design",
        "Other",
      ];
    },
    filteredUnis: function () {
      let unis = this.dbUnis;
      if (this.form.zone) {
        unis = unis.filter((u) => u.zone == this.form.zone);
      }
      if (this.form.state) {
        unis = unis.filter((u) => u.state == this.form.state);
      }
      if (this.form.city) {
        unis = unis.filter((u) => u.city == this.form.city);
      }
      if (this.form.field) {
        let key = {
          Architecture: "#a",
          "Interior Design": "#d",
          Planning: "#p",
          "Civil Engineering": "#c",
          Design: "#d",
        };

        if (Object.keys(key).includes(this.form.field)) {
          unis = unis.filter((u) => {
            if (u.categories) {
              return u.categories.includes(key[this.form.field]);
            }
            return false;
          });
        }
      }
      return unis;
    },
    filteredStates: function () {
      let zone = this.form.zone;
      let unis = this.dbUnis;
      if (zone) {
        unis = unis.filter((u) => u.zone == zone);
      }
      let states = unis
        .map((u) => u.state)
        .filter((v, i, s) => s.indexOf(v) == i)
        .sort();
      return states;
    },
    filteredCities: function () {
      let cities = this.filteredUnis
        .map((u) => u.city)
        .filter((v, i, s) => s.indexOf(v) == i)
        .sort();
      return cities;
    },
    userPages: function () {
      let count = this.counts[this.activeIndex];
      return Math.ceil(count / 10);
    },
    activeMassmail: function () {
      if (this.activeMassmailId) {
        return this.massMails.find((m) => m.id == this.activeMassmailId);
      } else {
        return null;
      }
    },
  },
  methods: {
    // logCount: function (m) {
    //   console.log("Massmail", m);
    //   let query = {
    //     _or: [...m.query],
    //     acceptingMassmail: 1,
    //   };
    //   this.$req
    //     .get(`users/count?where=${JSON.stringify(query)}`, this.$bus.headers)
    //     .then((r) => {
    //       console.log("Count", r.data);
    //     })
    //     .catch((e) => console.log(e));
    // },
    updateCompleted: function (id, completed) {
      this.$req
        .put(
          `mass-mails/${id}`,
          {
            Completed: !completed,
          },
          this.$bus.headers
        )
        .then((r) => {
          let mIx = this.massMails.findIndex((m) => m.id == id);
          this.$set(this.massMails[mIx], "Completed", r.data.Completed);
        })
        .catch((e) => console.log(e));
    },
    getUnis: function (filter) {
      let unis = this.dbUnis;
      if (filter.zone) {
        unis = unis.filter((u) => u.zone == filter.zone);
      }
      if (filter.state) {
        unis = unis.filter((u) => u.state == filter.state);
      }
      if (filter.city) {
        unis = unis.filter((u) => u.city == filter.city);
      }
      if (filter.field) {
        let key = {
          Architecture: "#a",
          "Interior Design": "#d",
          Planning: "#p",
          "Civil Engineering": "#c",
          Design: "#d",
        };

        if (Object.keys(key).includes(this.form.field)) {
          unis = unis.filter((u) => {
            if (u.categories) {
              return u.categories.includes(key[this.form.field]);
            }
            return false;
          });
        }
      }
      return unis;
    },
    addUnsubscribeLink: function (content) {
      let html = `
        <div style="font-size: 12px; text-align: center; cursor: pointer;">
          <a href="https://ethosempowers.com/#/unsubscribe"
             style="font-size: 12px;">Unsubscribe</a>
          <span>|</span>
          <a style="font-size: 12px;" href="mailto: info@ethosempowers.com">Support</a>
        </div>
      `;
      return content + html;
    },
    showList: function (ix) {
      this.activeIndex = ix;
      this.page = 0;
      this.activeFilter = this.mailList[this.activeIndex];
      /* eslint-disable-next-line no-undef */
      $("#mailingModal").modal("show");
    },
    deleteList: function (ix) {
      this.mailList.splice(ix, 1);
      this.counts.splice(ix, 1);
    },
    editMassmail: function (id) {
      this.activeMassmailId = id;
    },
    sendTestEmail: async function () {
      let emails = this.emails;
      if (!this.subject) {
        alert("Subject cannot be blank");
        return;
      }
      if (!this.content) {
        alert("Content cannot be blank");
        return;
      }
      let testEmails = Object.entries(this.testEmails)
        .filter((ent) => ent[1])
        .map((ent) => ent[0]);
      let filter = qs.stringify({
        _where: { email_in: testEmails },
      });
      let addresses = await this.$baseReq
        .get(`users?${filter}`, {
          headers: {
            Authorization: `Bearer ${this.$bus.token}`,
          },
        })
        .catch((e) => console.log(e));

      console.log(addresses?.data);
      if (addresses?.data) {
        this.$req
          .post(
            "mail",
            {
              emails: addresses.data,
              subject: this.subject,
              content: this.content,
            },
            {
              headers: {
                Authorization: `Bearer ${this.$bus.token}`,
              },
            }
          )
          .then(() => {
            this.$bus.notify("Mail sent");
            alert("Mail sent");
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    getGroupName: function (f) {
      let arr = [];
      let filter = { ...f };
      if (filter.levelOfEducation) {
        arr.push(filter.levelOfEducation);
      }
      if (filter.field) {
        arr.push(filter.field);
      }

      if (filter.type) {
        let type = filter.type;
        arr.push(type == "Faculty" ? type : type + "s");
      } else {
        arr.push("Users");
      }
      if (filter.institute) {
        arr.push("at " + filter.institute);
        delete filter["city"];
        delete filter["state"];
        delete filter["zone"];
      }
      if (filter.city) {
        arr.push("from " + filter.city);
        delete filter["state"];
        delete filter["zone"];
      }
      if (filter.state) {
        arr.push("from " + filter.state);
        delete filter["zone"];
      }
      if (filter.zone) {
        arr.push("from " + filter.zone + " zone");
      }
      if (filter.yearOfGraduation) {
        arr.push("graduating during " + filter.yearOfGraduation);
      }

      if (Object.keys(filter).length == 0) {
        arr = ["Upload"];
      }
      return arr.join(" ");
    },
    createMassmailJob: async function () {
      if (!this.subject) {
        alert("Subject cannot be blank");
        return;
      }
      if (!this.content) {
        alert("Content cannot be blank");
        return;
      }
      if (this.mailList.length == 0) {
        alert("Please add atleast one email");
        return;
      }
      let filter = qs.stringify({
        _where: { _or: this.mailList },
        _limit: -1,
      });

      let arr = [];
      for (let m of this.mailList) {
        let f = this.$bus.processForm(m, this.dbUnis);
        let _or = [...(f["_or"] || [])];
        let fields = { ...(f || {}) };
        delete fields["_or"];
        if (_or && _or.length > 0) {
          for (let condition of _or) {
            // console.log('Fields', fields, condition)
            arr.push({
              ...(fields || {}),
              ...(condition || {}),
            });
          }
        } else {
          arr.push(fields);
        }
      }

      const count = await this.logCount({
        query: arr,
      });
      // console.log(this.mailList)
      // Getting user count
      // let countResp = await this.$req.get(`users/count?${filter}`, {
      //   headers: {
      //     Authorization: `Bearer ${this.$bus.token}`
      //   }
      // }).catch(e => console.log(e))
      // console.log(this.mailList, countResp)
      // if (!countResp) {
      //   alert('Mailjob could not be created')
      //   return
      // }

      this.$req
        .post(
          "mass-mails",
          {
            query: arr,
            groups: this.mailList,
            EmailsPerBlast: 250 || this.EmailsPerBlast,
            frequency: 1,
            Subject: this.subject,
            source: this.source,
            Content: this.content,
            total: count,
            Starts: new Date(),
          },
          {
            headers: {
              Authorization: `Bearer ${this.$bus.token}`,
            },
          }
        )
        .then((r) => {
          this.massMails.push(r.data);
          this.state = "mails";
        })
        .catch((er) => {
          this.$bus.notify("An error occured", er);
        });
    },
    addMailingList: async function () {
      for (let k of Object.keys(this.form)) {
        if (this.form[k] == "") {
          delete this.form[k];
        }
      }
      await this.addList(this.form, false);
      this.clearForm();
    },
    addMiscUsers: async function () {
      const filter = {
        field_null: true,
        acceptingMassmail: true,
      };
      this.mailList.push({
        field_null: true,
      });
      this.$baseReq
        .get(
          `users/count?${qs.stringify({
            field_null: true,
            acceptingMassmail: true,
          })}`,
          this.$bus.headers
        )
        .then((r) => {
          this.counts.push(r.data);
        })
        .catch((e) => console.log(e));
    },
    addCustomMailingList: async function () {
      let list = this.mailingLists.find(
        (ml) => this.selectedMailingList == ml.id
      );
      await this.addList(list.filter, false);
    },
    addList: async function (form, addCounts = true) {
      let f = this.$bus.processForm(form, this.dbUnis, this.yogeq);
      console.log(f);
      if (!addCounts) {
        const count = await this.logCount({
          query: form,
        });
        console.log(count);
        // console.log("Count", count);
        // let countResp = await this.$req
        //   .post(
        //     "getUsers",
        //     {
        //       filter: {
        //         _or: [...Object.values(f)],
        //         acceptingMassmail: 1,
        //       },
        //       limit: 1,
        //       page: 0,
        //     },
        //     this.$bus.headers
        //   )
        //   .catch((e) => console.log(e));
        // if (countResp) {
        //   this.counts.push(countResp.data.count);
        // } else {
        //   this.counts.push("");
        // }
        this.$baseReq
          .get(
            `users/count?${qs.stringify({
              ...f,
            })}`,
            this.$bus.headers
          )
          .then((r) => {
            this.counts.push(r.data);
          })
          .catch((e) => {
            console.log(e);
            this.counts.push("");
          });
      }
      this.mailList.push({ ...form });
    },
    logCount: async function (m) {
      let f = this.$bus.processForm(m.query, this.dbUnis, this.yogeq);
      console.log("Log filter", f);
      let countResp = await this.$req
        .post(
          "getUsers",
          {
            filter: {
              _or: [...Object.values(f)],
              acceptingMassmail: 1,
            },
            limit: 10,
            page: 0,
          },
          this.$bus.headers
        )
        .catch((e) => {
          console.log("Error while logging count.");
          console.log(e);
        });
      if (countResp) {
        console.log("Count data", countResp.data.count);
        return countResp.data.count;
      }
    },
    fixCount: async function (m) {
      // Uses the last function to get the count and
      // then updates the count in the massmail object
      let count = await this.logCount(m);
      if (count) {
        m.total = count;
      }
      // Update the count on strapi
      this.$req
        .put(
          `mass-mails/${m.id}`,
          {
            total: count,
          },
          this.$bus.headers
        )
        .catch((e) => console.log(e));
    },
    deleteMassmail: function (id) {
      if (confirm("Are you sure?") == true) {
        this.$req
          .delete(`mass-mails/${id}`)
          .then((r) => {
            let ix = this.massMails.find((m) => m.id == id);
            this.massMails.splice(ix, 1);
            alert("Massmail deleted");
          })
          .catch((e) => console.log(e));
      }
    },
    clearForm: function () {
      for (let k of Object.keys(this.form)) {
        this.$set(this.form, k, "");
      }
      this.search = "";
    },
    csvJSON: function (str, headerList, quotechar = '"', delimiter = ",") {
      // const cutlast = (_, i, a) => i < a.length;
      // const regex = /(?:[\t ]?)+("+)?(.*?)\1(?:[\t ]?)+(?:,|$)/gm; // no variable chars
      const regex = new RegExp(
        `(?:[\\t ]?)+(${quotechar}+)?(.*?)\\1(?:[\\t ]?)+(?:${delimiter}|$)`,
        "gm"
      );
      const lines = str.split("\n");
      var headers =
        headerList ||
        lines
          .splice(0, 1)[0]
          .match(regex)
          .filter((h) => h)
          .map((h) => h.trim());
      headers = headers.filter((h) => h);
      const list = [];
      for (const line of lines) {
        const val = {};
        for (const [i, m] of [...line.matchAll(regex)].entries()) {
          // Attempt to convert to Number if possible, also use null if blank
          if (headers[i])
            val[headers[i]] = m[2].length > 0 ? Number(m[2]) || m[2] : null;
        }
        list.push(val);
      }
      return list;
    },
    uploadCreate: function () {
      let ctx = this;
      let listName = this.listName;
      var el = document.createElement("input");
      let reader = new FileReader();
      el.setAttribute("type", "file");
      el.addEventListener("change", function () {
        reader.readAsText(this.files[0]);
        reader.onload = () => {
          let csv = reader.result;
          let json = ctx.csvJSON(csv, ["name", "email"]);
          json = json.slice(1, json.length);
          let filter = {
            email_in: json.map((u) => u.email).filter((em) => em),
          };
          // ctx.addList(filter)
          // ctx.counts.push(json.length)
          ctx.$req
            .post(
              "mailing-lists",
              {
                name: listName,
                filter,
              },
              ctx.$bus.headers
            )
            .then((r) => {
              ctx.listName = "";
              ctx.mailingLists.push(r.data);
              ctx.$bus.notify("List uploaded successfully");
            })
            .catch((e) => console.log(e));
        };
      });
      el.click();
    },
    upload: function () {
      let ctx = this;
      var el = document.createElement("input");
      let reader = new FileReader();
      el.setAttribute("type", "file");
      el.addEventListener("change", function () {
        reader.readAsText(this.files[0]);
        reader.onload = () => {
          let csv = reader.result;
          let json = ctx.csvJSON(csv, ["name", "email"]);
          json = json.slice(1, json.length);
          let filter = {
            email_in: json.map((u) => u.email),
          };
          ctx.addList(filter);
          ctx.counts.push(json.length);
        };
      });
      el.click();
    },
  },
};
</script>

<style lang="css">
.ProseMirror {
  outline: none;
}
.menubar {
  margin: 8px 0px -10px 0px;
}
.editor {
  padding: 12px;
  min-height: 160px;
}
.menubar__button {
  background: transparent;
  padding: 8px;
}
.menubar__button:hover {
  background: rgba(0, 0, 0, 0.05);
}
.mailPopup {
  padding: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.mailPopup label {
  margin-left: 5%;
}
.emails {
  width: 90%;
  margin: 8px 5%;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 8px 4px;
  height: 80px;
  overflow-y: scroll;
}
.emails .address {
  margin: 4px 4px;
  border-radius: 2px;
  height: 28px;
  display: inline-flex;
  font-size: 12px;
}
.emails .address i {
  margin: auto 4px !important;
}
.email-content {
  width: 100%;
  margin-top: 20px;
  font-size: 12px;
  border: 1px solid #eee;
}
.sendEmailButton {
  margin-left: auto;
}
.mail-group {
  border: 1px solid #eee;
  padding: 8px;
  margin-top: 12px;
  border-radius: 4px;
  width: fit-content;
}
</style>
